import React from "react";
import { WaterFilledTest } from "../funStuff/WaterFillDecoratorComponent.js";
import { ToggleSwitchTest } from "../funStuff/toggleSwitch.js";
import { Section } from "./Section.js";
import "../../styles/components/WidgetsSection.scss";
import { OrbitsDemo } from "../funStuff/orbits.js";
import { CyclesDemo } from "../funStuff/cycles.js";
import { AnimationAPIDemo } from "../funStuff/animationAPIDemo.js";
export const WidgetsSection = () => {
    return (React.createElement(Section, null,
        React.createElement(WidgetsContent, null)));
};
export const WidgetsContent = () => {
    return (React.createElement("div", { className: "widgets" },
        React.createElement("div", { className: "cards" }, info.map(card => React.createElement(Card, { title: card.title, key: card.title, description: card.description, content: card.content })))));
};
const Card = ({ title, content, description }) => {
    return (React.createElement("article", { className: "card" },
        React.createElement("div", { className: "content" }, content),
        React.createElement("h2", null, title),
        React.createElement("p", { className: "description" }, description)));
};
const info = [
    {
        title: "Water Slosh",
        content: React.createElement(WaterFilledTest, null),
        description: React.createElement("span", null,
            "A CSS-only water sloshing effect that I saw in ",
            React.createElement("a", { href: "https://www.youtube.com/watch?v=20RmuBA1cj4&t=1s&ab_channel=DuctTapeDev", target: "_blank" }, "this video"),
            ".  I implemented it myself as a React Higher Order Component; in this example, its wrapped around a simple \"hello world\" div.")
    },
    {
        title: "Toggle Switches",
        content: React.createElement(ToggleSwitchTest, null),
        description: React.createElement("span", null, "A simple toggle switch in React. Unlike most other similar switches that I see online, which use hard-coded widths, this one can be easily set to different sizes with no loss in functionality")
    },
    {
        title: "Orbits",
        content: React.createElement(OrbitsDemo, null),
        description: React.createElement("span", null, "Some fun with CSS transforms, animations, and gradients.  This is CSS-only (except for the random color selector).")
    },
    {
        title: "Cycles",
        content: React.createElement(CyclesDemo, null),
        description: React.createElement("span", null, " Simple cyclic animation using SVGs and setInterval.  I made something like when I was first learning to code and thought I'd try again.")
    },
    {
        title: "Web Animations API",
        content: React.createElement(AnimationAPIDemo, null),
        description: React.createElement("span", null,
            "Demonstration of the new-ish ",
            React.createElement("a", { href: "https://developer.mozilla.org/en-US/docs/Web/API/Web_Animations_API" }, "Web Animations API"),
            ", which allows us to programmatically pause and resume animations, among other things.  Hover over the demo to try it out!")
    }
];
