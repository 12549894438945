import React, { useState } from "react";
import { useEffect } from "react";
export const Exercise9 = () => {
    return React.createElement(SearchableUserList, null);
};
const SearchableUserList = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [query, setQuery] = useState("");
    const getUsers = async () => {
        const userList = await (await fetch("https://randomuser.me/api?results=40")).json();
        const userArray = [];
        for (const user of userList.results) {
            userArray.push(`${user.name.title} ${user.name.first} ${user.name.last}`);
        }
        setAllUsers(userArray);
        setSelectedUsers(userArray);
    };
    const onQueryChange = (e) => {
        const longer = e.target.value.length > query.length;
        setQuery(e.target.value);
        const newSelected = [];
        const searchArray = longer ? selectedUsers : allUsers;
        for (const user of searchArray) {
            if (user.toLocaleLowerCase().indexOf(e.target.value.toLocaleLowerCase()) !== -1) {
                newSelected.push(user);
            }
        }
        setSelectedUsers(newSelected);
    };
    useEffect(() => { getUsers(); }, []);
    return (React.createElement("div", null,
        React.createElement("input", { onChange: onQueryChange, type: "text", placeholder: "Search for names" }),
        React.createElement("ul", null, selectedUsers.map((user) => React.createElement("li", null, user)))));
};
export default Exercise9;
