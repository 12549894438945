import React from "react";
import "../../styles/components/HeroSection.scss";
import { Section } from "./Section.js";
import { TypingEffect } from "../funStuff/TypingEffect.js";
export const HeroSection = () => {
    return (React.createElement(Section, null,
        React.createElement(HeroContent, null)));
};
export const HeroContent = () => {
    return (React.createElement("div", { className: "heroSection" },
        React.createElement("div", null,
            React.createElement("h1", null,
                " ",
                React.createElement(TypingEffect, { message: "Hi, my name is Jake!" })),
            React.createElement("h3", null, " I'm a software engineer with 6 years professional experience doing full-stack web dev.  Currently, my focus is on React and Node.js. "),
            React.createElement("h3", null, " Below, you can see some of my side projects, in varying states of completion, as well as some of my interview prep.  "),
            React.createElement("h3", null, " Besides the core React packages, I created everything on this page from scratch, without external libraries or packages. ")),
        React.createElement("div", { className: "portrait" })));
};
