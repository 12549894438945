import React, { useState } from "react";
const QUESTIONS = [
    {
        question: "What is 2*(4+4)?",
        answers: ["2", "4", "8", "16"],
        correct: 3,
    },
    {
        question: "What is 9*9?",
        answers: ["18", "81", "80", "79"],
        correct: 1,
    },
    {
        question: "Who was the first president of the United States?",
        answers: [
            "George Washington",
            "John Adams",
            "John Quincy Adams",
            "Thomas Jefferson",
        ],
        correct: 0,
    },
    {
        question: "What state is Philadelphia in?",
        answers: [
            "Commonwealth of Pennsylvania",
            "New Jersey",
            "New York",
            "Massachusetts",
        ],
        correct: 0,
    },
    {
        question: "What are the two major political parties in the United States?",
        answers: [
            "Democratic Party & Republican Party",
            "Green Party & Red Party",
            "Bull Party & Moose Party",
            "Hamilton Party & Burr Party",
        ],
        correct: 0,
    },
];
export default function Exercise8() {
    return React.createElement(Quiz, { questions: QUESTIONS });
}
const Quiz = ({ questions }) => {
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [scores, setScores] = useState([]);
    const onClickAnswer = (idx) => {
        const curQuestion = QUESTIONS[currentQuestion];
        if (curQuestion.correct === idx) {
            setScores([...scores, 1]);
        }
        else {
            setScores([...scores, 0]);
        }
        setCurrentQuestion(currentQuestion + 1);
    };
    const onReset = () => {
        setScores([]);
        setCurrentQuestion(0);
    };
    const goBack = () => {
        setCurrentQuestion(currentQuestion - 1);
        setScores(scores.slice(0, scores.length - 1));
    };
    // YOUR CODE HERE
    return React.createElement("div", null,
        currentQuestion < questions.length &&
            React.createElement(OneQuestion, { question: questions[currentQuestion], onClickAnswer: onClickAnswer }),
        currentQuestion >= questions.length &&
            React.createElement("div", null,
                React.createElement("h2", null, `Your score is ${(scores.reduce((cur, sum) => sum += cur)) / questions.length * 100}%`),
                React.createElement("input", { type: "button", onClick: onReset, value: "Reset" })),
        currentQuestion > 0 && React.createElement("input", { type: "button", value: "Back", onClick: goBack }));
};
const OneQuestion = ({ question, onClickAnswer }) => {
    return (React.createElement("div", null,
        React.createElement("h2", null, question.question),
        React.createElement("ul", null, question.answers.map((ans, idx) => React.createElement("li", { onClick: () => onClickAnswer(idx), style: { cursor: "pointer" } }, ans)))));
};
