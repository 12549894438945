import React, { useState } from "react";
export default function Exercise7() {
    const [strings, setStrings] = useState([]);
    const [currentVal, setCurrentVal] = useState("");
    const [modalString, setModalString] = useState("");
    //const modalRef = useRef<HTMLDivElement>();
    const onSubmit = (e) => {
        e.preventDefault();
        if (currentVal.length > 0) {
            setStrings([...strings, currentVal]);
        }
        setCurrentVal("");
    };
    const onChangeInput = (e) => {
        setCurrentVal(e.currentTarget.value);
    };
    const onClickString = (s) => {
        setModalString(s);
    };
    const onClickOffModal = (event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        setModalString("");
    };
    return (React.createElement("div", { style: { width: "500px", height: "600px", position: "relative", overflowY: "scroll" } },
        React.createElement("div", { style: { display: "flex", flexDirection: "column", alignItems: "center" } },
            React.createElement("form", { onSubmit: onSubmit },
                React.createElement("input", { type: "text", value: currentVal, onChange: onChangeInput })),
            React.createElement("div", { style: { display: "flex", flexDirection: "column", cursor: "pointer" } }, strings.map((s) => {
                let dispString = s;
                if (s.length > 6) {
                    dispString = dispString.slice(0, 6) + "...";
                }
                return React.createElement("div", { onClick: () => onClickString(s) },
                    " ",
                    dispString,
                    " ");
            }))),
        modalString.length > 0 && React.createElement(ModalPopup, { str: modalString, onClose: onClickOffModal })));
}
;
const ModalPopup = ({ str, onClose }) => {
    const ignoreClickOnPopup = (event) => {
        event.stopPropagation();
    };
    return (React.createElement("div", { style: { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", top: 0, backgroundColor: "rgba(255, 255, 255, .5)" }, onClick: onClose },
        React.createElement("div", { style: { height: "300px", width: "300px", color: "white", textAlign: "center", backgroundColor: "black" }, onClick: ignoreClickOnPopup }, "full text is: " + str)));
};
