import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "../../styles/reactTraining/reactTraining.scss";
import Exercise1 from './exercise1.js';
import Exercise2 from './exercise2.js';
import Exercise3 from './exercise3.js';
import Exercise4 from './exercise4.js';
import Exercise5 from './exercise5.js';
import Exercise6 from './exercise6.js';
import Exercise7 from './exercise7.js';
import Exercise8 from './exercise8.js';
import Exercise9 from './exercise9.js';
import Exercise10 from './exercise10.js';
export const ExerciseList = () => {
    return (React.createElement("div", { className: "reactExercises" },
        React.createElement("div", { className: "linkList" },
            Array.from(Array(26).keys()).map((num) => React.createElement(NavLink, { to: `./Exercise${num + 1}`, style: { fontSize: "14pt" } }, `Excerise ${num + 1}`)),
            React.createElement(NavLink, { to: "./atlassianInterview", style: { fontSize: "14pt" } }, "Atlassian Interview")),
        React.createElement("div", { className: "content" },
            React.createElement(Outlet, null))));
};
export const Exercises = {
    Exercise1: React.createElement(Exercise1, null),
    Exercise2: React.createElement(Exercise2, null),
    Exercise3: React.createElement(Exercise3, null),
    Exercise4: React.createElement(Exercise4, null),
    Exercise5: React.createElement(Exercise5, null),
    Exercise6: React.createElement(Exercise6, null),
    Exercise7: React.createElement(Exercise7, null),
    Exercise8: React.createElement(Exercise8, null),
    Exercise9: React.createElement(Exercise9, null),
    Exercise10: React.createElement(Exercise10, null),
    // Exercise11: <Exercise11/>,
    // Exercise12: <Exercise12/>,
    // Exercise13: <Exercise13/>,
    // Exercise14: <Exercise14/>,
    // Exercise15: <Exercise15/>,
    // Exercise16: <Exercise16/>,
    // Exercise17: <Exercise17/>,
};
