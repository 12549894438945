import React, { useState } from "react";
import "./toggleSwitch.scss";
const ToggleSwitch = ({ onSwitch }) => {
    const [toggled, setToggled] = useState(false);
    const onClickSwitch = () => {
        onSwitch(!toggled);
        setToggled(!toggled);
    };
    const additionalCSS = toggled ? " toggledOn" : "";
    return (React.createElement("div", { className: "switchBody" + additionalCSS, onClick: onClickSwitch },
        React.createElement("div", { className: "switch" + additionalCSS })));
};
export const ToggleSwitchTest = () => {
    const [count, setCount] = useState(0);
    const handleSwitch = (isToggled) => {
        if (isToggled) {
            setCount(count + 1);
        }
        else {
            setCount(count - 1);
        }
    };
    return (React.createElement("div", { style: { backgroundColor: count === 3 ? "lightgreen" : "coral", padding: "10px", width: "100%", aspectRatio: "1/1", boxSizing: "border-box", display: "flex", flexDirection: "column", alignItems: "center" } },
        React.createElement("div", { style: { width: "200px", height: "100px" } },
            React.createElement(ToggleSwitch, { onSwitch: handleSwitch })),
        React.createElement("div", { style: { width: "100%", height: "50px", margin: "25px 0px" } },
            React.createElement(ToggleSwitch, { onSwitch: handleSwitch })),
        React.createElement("div", { style: { width: "60px", height: "30px" } },
            React.createElement(ToggleSwitch, { onSwitch: handleSwitch }))));
};
export default ToggleSwitch;
