import React, { useState } from "react";
const LADDER_IMAGE = "https://raw.githubusercontent.com/jusshe/coding-challenge-pictures/main/ladder.png";
export default function Exercise4() {
    return React.createElement(Ladder, { img: LADDER_IMAGE });
}
const Ladder = ({ img }) => {
    const length = 5;
    const [zooms, setZooms] = useState(new Array(length).fill(false));
    const onZoom = (index) => {
        const newZooms = new Array(length).fill(false);
        for (let i = index; i < length; i++) {
            newZooms[i] = true;
        }
        setZooms(newZooms);
    };
    const onUnZoom = () => {
        setZooms(new Array(length).fill(false));
    };
    const ladders = [];
    for (let i = 0; i < length; i++) {
        ladders.push(React.createElement(LadderSegment, { img: img, index: i, zoomed: zooms[i], onZoom: onZoom, onUnZoom: onUnZoom }));
    }
    return (React.createElement("div", { style: { display: "flex", alignItems: "center", flexDirection: "column" } }, ladders));
    // YOUR CODE HERE
};
const LadderSegment = ({ img, index, zoomed, onZoom, onUnZoom }) => {
    return (React.createElement("img", { src: img, alt: "a ladder segment", style: zoomed ? { width: "200px", height: "200px" } : { width: "100px", height: "100px" }, onPointerEnter: onZoom.bind(this, index), onPointerLeave: onUnZoom }));
};
